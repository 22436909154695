import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <span className="icon fa-diamond"></span>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Ociel Marin</h1>
        <p>
          A software engineer with entry-level experience in data science, <br></br>
          test engineering, and cloud ops. Most recently a Test Engineer<br></br>
          at Zoom, and now looking for new opportunities to further expertise.<br></br>
          See <a href="http://resume.ocielmar.in">my resume here</a>{' '}and reach out
          to me through my contact links below!
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Projects
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
