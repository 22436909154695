import PropTypes from 'prop-types'
import React from 'react'
import pic_about from '../images/pic_about.jpg'
import pic_bpm from '../images/bpm.jpg'
import pic_dontdie from '../images/dontdie.jpg'
import pic_graph from '../images/graph.jpg'
import pic_ssdbot from '../images/ssdbot.jpg'
import pic_arcade from '../images/arcade.jpg'
import pic_gallery from '../images/gallery.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <p>
            The following are some projects I have contributed to:
            <br></br>
            <b>(Please contact me to view private source code or repositories)</b>
          </p>


          <span className="image main">
            <img src={pic_bpm} alt="" />
          </span>
          <h3>
            <a href="http://bpm.ocielmar.in/">
              Bookstore
            </a>
          </h3>
          <p>
            <i className="icon fa-terminal"></i> Python, Django, HTML, Bootstrap
            <br></br>A bookstore website with a user, book, and purchase system.
            Visitors are able to view and search for available books, then able
            to purchase with a registered account. Admins are able to edit all
            database entries. This was done as a group project for CSCI4050 at
            UGA. I contributed heavily in backend but helped on every aspect of
            the website.
          </p>


          <span className="image main">
            <img src={pic_graph} alt="" />
          </span>
          <h3>
            <a href="http://tsent.ocielmar.in/">
              COVID-19 Tweet Sentiment Analysis
            </a>
          </h3>
          <p>
            <i className="icon fa-terminal"></i> {' '}Python, sklearn, Jupyter
            <br></br>This was a collborative project making use of data science analysis.
            The project involved making use of a publicly available dataset (in this case,
            a conglomerate of Tweets pertaining to the pandemic from Kaggle) and
            performing sentiment analysis on it. This involved scraping the data, sanitizing data,
            performance testing on multiple prediction models, parameter tuning, creating
            an appropriate featureset, and visualizing the data. In the end,
            we were able to create a fairly accurate model and formulated{' '}
            <a href="http://tsentdoc.ocielmar.in"><b>a paper with our findings.
            </b></a>
          </p>


          <span className="image main">
            <img src={pic_ssdbot} alt="" />
          </span>
          <h3>
            <a href="https://github.com/ocmarin/ssd-bot">
              SSD Bot
            </a>
          </h3>
          <p>
            <i className="icon fa-terminal"></i> Python, Reddit API
            <br></br>An automated Reddit bot that searches for computer hardware (SSDs)
            when a new post is created, then comments useful information about the mentioned hardware.
            This functions by searching through the titles on posts on r/BuildAPCSales then
            attempts to find SSDs from a public and commonly referenced spreadsheet.
          </p>


          <span className="image main">
            <img src={pic_arcade} alt="Arcade" />
          </span>
          <h3>
            <a href="http://cs1302.ocielmar.in/">
              JavaFX Arcade
            </a>
          </h3>
          <p>
            <i className="icon fa-terminal"></i> Java, JavaFX
            <br></br>This was a small group project implementing some timeless video games
            (Tetris &amp; 2048).
            The graphical interface was built from scratch with the usage of the
            open source JavaFX library. The games themselves also feature leaderboards
            alongside all expected functionality of the games.
          </p>


          <span className="image main">
            <img src={pic_gallery} alt="Gallery" />
          </span>
          <h3>
            <a href="http://cs1302.ocielmar.in/">
              iTunes Image Gallery
            </a>
          </h3>
          <p>
            <i className="icon fa-terminal"></i> Java, JavaFX
            <br></br>The iTunes Gallery was also built on the previously mentioned JavaFX library.
            As for the functionality, the program accepts a search query in order
            to populate the screen with cover art of the specified query.
            Then it cycles through other artwork from the query with a fading in animation.
            This program highlights the usage of iTunes' API alongside
            basic multithreading functionality within a graphical interface.
          </p>



          <span className="image main">
            <img src={pic_dontdie} alt="" />
          </span>
          <h3>
            <a href="https://devpost.com/software/don-t-die-in-space">
              Don't Die in Space
            </a>
          </h3>
          <p>
            <i className="icon fa-terminal"></i> HTML, Javascript, React.js,
            Bootstrap
            <br></br>A simple space multiplayer game in which players are
            spaceships cruising through an asteroid belt, attempting to
            eliminate each other. This was done at UGAHacks, a weekend long
            hackathon. My involvement was focused on front-end and client side
            development.
          </p>


          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <p>
            Pursuing a computer science degree at the University of Georgia (and having loved technology all
            my life) has given me ample opportunity to hone my development and communication
            skills. I'm versed in many tools including Java, C++, Python (Pandas, sklearn, Django, Flask),
            Javascript (node.js, React.js), HTML, CSS (Bootstrap), Weka, and the Linux command line.
          </p>
          <p>
            I've explored object-oriented programming (OOP) languages since I was about 14 years old.
            At every opportunity, I try to learn new methods, programs, applications,
            and various other technologies to improve my capabilities and become a better teammate.
          </p>
          <p>
            Currently I'm located in the{' '}
            <a href="https://www.google.com/maps?q=Atlanta,+GA">Atlanta, GA</a>{' '}
            area, but I'm always <b>open to remote opportunities elsewhere,</b> regardless of location.</p>
          <p>Interested in my{' '}
            <b>
              <i>work history?</i>
            </b>
            {' '}See my <a href="http://resume.ocielmar.in">resume here.</a>
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''
            }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <h3 classname="major">Interested in Contacting Me?</h3>
          <p>
            I'm always open to new projects to pursue or areas within my expertise to work on.
            Use any of the below methods to get in touch with me and I'll return your
            message as soon as I can.
          </p>
          <ul className="icons">
            <li>
              <a
                href="mailto:theocielmarin@gmail.com"
                className="icon fa-envelope"
              >
                <span className="label">Email</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/ocmarin/" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/ociel-marin-21aab315a"
                className="icon fa-linkedin"
              ></a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
